import React, { Component } from "react";
import { connect } from "react-redux";
import { updateOob } from "../redux/actions/miscActions";

export class OOB extends Component {
  handleIncrement = () => {
    this.props.updateOob();
  };

  render() {
    return (
      <div className="results-data">
        <h1>Public Interest Entities</h1>
        <p className="sub">
          Reporting standard to be used: <span>Full IFRS</span>
        </p>
        <p className="small-header">Definition of a Public Interest Entity</p>
        <p className="oob-expl">
          Public Interest Entities (so-called PIEs) have also been included in
          the scope of the "Wet op de Jaarrekening". These are not a legal form
          in itself, but a qualification of types of companies that are of such
          social importance that poor management and financial unaccountability
          would therefore have a large negative impact on the economy. The law
          defines PIEs as:
        </p>
        <ul className="roman">
          <li>
            <span>
              public limited company established in Suriname of which issued
              securities are publicly are traded or have a public listing
            </span>
          </li>
          <li>
            <span>
              a (supervised) credit institution established in Suriname
            </span>
          </li>
          <li>
            <span>a (supervised) insurer established in Suriname</span>
          </li>
          <li>
            <span>a (supervised) pension fund established in Suriname</span>
          </li>
          <li>
            <span>
              (partially) stated owned institutions, regardless of their size
              and legal form, insofar as they do not already fall under an
              organization referred to under points “a” to “d”.
            </span>
          </li>
        </ul>

        <p className="small-header">PIEs are required to use Full IFRS</p>
        <div className="result-par">
          <ol>
            <li>
              Three book years to be presented on the Statement of Financial
              Position
            </li>
            <p>
              <strong>Example:</strong>
            </p>
            <p>
              Implementing IFRS in a company with a book year ending December
              31, 2020 would require presentation of the following year-end
              balances on its Statement of Financial Position:
            </p>
            <table>
              <thead>
                <tr>
                  <td>December 31, 2020</td>
                  <td>December 31, 2019</td>
                  <td>As at January 1, 2019</td>
                </tr>
              </thead>
            </table>
            <li>
              Full set of financial statements which consist of the following:{" "}
              <ul>
                <li>Statement of Profit or Loss</li>
                <li>Statement of Comprehensive Income</li>
                <li>Statement of Financial Position</li>
                <li>Statement of Changes in Equity</li>
                <li>Statement of Cash Flows</li>
                <li>Notes to Financial Statements </li>
              </ul>
            </li>
            <li>
              Disclosures on Restatements and First-Time Adoption of IFRS
              presenting all IFRS conversion adjustments and the impact it has
              had on the previous accounting procedures.
            </li>
          </ol>
        </div>

        <a
          href="https://hintzenadministration-consulting.com/wp-content/uploads/2021/12/IFRS-Guidelines-for-PIEs.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button onClick={this.handleIncrement} className="download">
            Download
          </button>
        </a>
      </div>
    );
  }
}

export default connect(null, { updateOob })(OOB);
