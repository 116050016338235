import  { combineReducers } from 'redux'
import miscReducer from './miscReducer'

const appReducer = combineReducers({
  misc: miscReducer,
})

const rootReducer = (state, action) => {


  return appReducer(state, action)
}

export default rootReducer