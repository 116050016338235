import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const middleware = [thunk]
const initialState = {}

const composeEnhancers = composeWithDevTools({ 
  trace: true,
  traceLimit: 25,
})

// export default createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()))


export default createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)))