import React, { Component } from 'react'
import 'dayjs'
import { Table } from 'antd'
import dayjs from 'dayjs'

export class Results extends Component {
  render() {
    const { results } = this.props
    const data = results.sort((a, b) => b.date.toDate() - a.date.toDate())
    const columns = [
      {
        title: 'Result',
        dataIndex: 'result',
        key: 'result' 
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: date => dayjs(date.seconds * 1000 ).format('DD MMMM YYYY @ hh:mm A')
      }
    ]
    return (
      <div>
        <p>Results</p>
        <Table columns={columns} dataSource={data} />
      </div>
    )
  }
}

export default Results
