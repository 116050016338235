import firebase from 'firebase'
import { firestoreDB } from '../../config/firebase'
import { CALCULATING, LOADER, MESSAGE, VIDEO_MODAL, OOB, RESET_RESULT, TOGGLE_SECRET, SHOW_ADMIN_MODAL, GET_RESULTS, GET_MESSAGES, GET_COUNTER_RESULTS } from "./actionTypes";


export const handleOob=()=>dispatch=>{  
  dispatch(loader(true))
  setTimeout(()=>{
    dispatch({
      type: OOB
    })
    dispatch(updateDatabaseResults('oob'))
  },2300)

}

export const resetResult=()=>({
  type: RESET_RESULT
})


export const calculate=(bw, no, ps)=> dispatch => {

  let rechtspersoon;
  
  if(bw === '<=3mln' && no === '<=6mln' || bw === '<=3mln' && ps === '<=20' || no === '<=6mln' && ps === '<=20'){
    rechtspersoon = 'klein'
  }
  
  if( bw === '<=3mln' && no === '>6mln' && ps === '>50' || bw === '>3mln' && no === '<=6mln' && ps === '>50' || bw === '>3mln' && no === '>6mln' || bw === '>3mln' && ps === '>20' || no === '>6mln' && ps === '>20'){
    rechtspersoon = 'middelgroot'
  }
  
  if( bw === '>12mln' && no === '<=6mln' && ps === '>20' || bw === '>12mln' && no === '>6mln' && ps === '<=20' || bw === '<=3mln' && no === '>24mln' && ps === '>20'  || bw === '>12mln' && no === '>24mln' || bw === '>12mln' && ps === '>50' || no === '>24mln' && ps === '>50'){
    rechtspersoon = 'groot'
  }

  dispatch(loader(true))
  
  setTimeout(()=>{
    dispatch({
      type: CALCULATING,
      payload: rechtspersoon
    })
    dispatch(updateDatabaseResults(rechtspersoon))
  },2400)
  

  
}

export const updateDatabaseResults=(result)=> dispatch =>{
  firestoreDB.collection('results').add({
    result,
    date: firebase.firestore.FieldValue.serverTimestamp(),
    doc: 'hac'
  }).then(function(doc){
    dispatch({
      type: LOADER,
      payload: false
    })
  })
}

export const updateDatabaseMessages=(data)=> dispatch =>{
  const msgData = {
    bool: true,
    msg: 'Your message has been sent.',
    type: 'success'
  }
  firestoreDB.collection('messages').add({
    date: firebase.firestore.FieldValue.serverTimestamp(),
    email: data.email,
    msg: data.msg,
    name: data.name,
    phone: data.phone,
    doc: 'hac',
    rechtspersoon: data.rechtspersoon
  }).then(function(doc){
    dispatch({
      type: MESSAGE,
      payload: msgData
    })
  })
}

export const loader=(bool)=>({
  type: LOADER,
  payload: bool
})

export const showMessage=(data)=>({
  type: MESSAGE,
  payload: data
})

export const toggleVideoModal=(data)=>({
  type: VIDEO_MODAL,
  payload: data
})

export const toggleSecret = (bool) => ({
  type: TOGGLE_SECRET,
  payload: bool
})

export const showAdminModal = (bool) =>({
  type: SHOW_ADMIN_MODAL,
  payload: bool
})

export const getresults = () => dispatch => {
  firestoreDB.collection('messages').where('doc', '==', 'hac').onSnapshot(function(querySnapshot){
    let msgs = []
    querySnapshot.forEach(function(doc){
      const msg = doc.data();
      msg.id = doc.id;
      msg.key = doc.id
      msgs.push(msg)
    })
    dispatch({
      type: GET_MESSAGES,
      payload: msgs
    })
  })
}

export const getmsg = () => dispatch => {
  firestoreDB.collection('results').where('doc', '==', 'hac').onSnapshot(function(querySnapshot){
    let results = []
    querySnapshot.forEach(function(doc){
      const result = doc.data();
      result.id = doc.id;
      result.key = doc.id
      results.push(result)
    })
    dispatch({
      type: GET_RESULTS,
      payload: results
    })
  })
}

export const updateOob = () => dispatch => {
  firestoreDB.collection('docs').doc('oob').update({
    downloaded: firebase.firestore.FieldValue.increment(1)
  }).then(
    dispatch(loader(false))
  )
}

export const updateFull = () => dispatch => {
  firestoreDB.collection('docs').doc('full').update({
    downloaded: firebase.firestore.FieldValue.increment(1)
  }).then(
    dispatch(loader(false))
  )
}

export const updateSme = () => dispatch => {
  firestoreDB.collection('docs').doc('sme').update({
    downloaded: firebase.firestore.FieldValue.increment(1)
  }).then(
    dispatch(loader(false))
  )
}

export const getDocCollection =() => dispatch => {
  firestoreDB.collection('docs').where('downloaded', '>=', 0).onSnapshot(function(querySnapshot){
    var counter = [];
    querySnapshot.forEach(function(doc){
      const count = doc.data()
      count.id = doc.id
      counter.push(count)
    })
    dispatch({
      type: GET_COUNTER_RESULTS,
      payload: counter
    })
  })
}