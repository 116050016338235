import React, { Component } from 'react'

export class Hero extends Component {
 
  render() {
    return (
      <div className="hero">
          
        {/* <video 
        // poster={require('../assets/images/video-poster.png')} 
        autoPlay loop >
          <source src='https://firebasestorage.googleapis.com/v0/b/hac-ifrs.appspot.com/o/pexels-fauxels-3183165_2.mp4?alt=media&token=eedf9161-cc8b-4644-a818-d8b691573497' type='video/mp4'/>
        </video> */}
        <div className="hero-container">
        <h1>Use our free tool to find out how to get your business ready for IFRS</h1>
<div className="down-arrow">
<i className="icofont-rounded-double-right"></i>
</div>
        </div>
      </div>
    )
  }
}

export default Hero
