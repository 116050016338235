import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyCzMZzchKHvJdBL9VrEKs8Gi3ydJSCaz1k",
  authDomain: "hac-ifrs.firebaseapp.com",
  databaseURL: "https://hac-ifrs.firebaseio.com",
  projectId: "hac-ifrs",
  storageBucket: "hac-ifrs.appspot.com",
  messagingSenderId: "580680536450",
  appId: "1:580680536450:web:7f50c66b514b12b8f83f9b",
  measurementId: "G-C9PLN5ZKNM"
};

firebase.initializeApp(firebaseConfig);

export const firestoreDB = firebase.firestore()
