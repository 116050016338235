import React, { Component } from "react";
import { connect } from 'react-redux'
import {calculate, showMessage, handleOob} from '../redux/actions/miscActions'
import { Select, Popover } from "antd";

const { Option } = Select;

export class InputSection extends Component {
  constructor(props){
    super(props);
    this.state={
      bw: '',
      no: '',
      ps: ''
    }
  }

  selectBalanswaarde=(value)=>{
    this.setState({
      bw: value
    })
  }

  selectNettoOmzet=(value)=>{
    this.setState({
      no: value
    })
  }

  selectPersoneel=(value)=>{
    this.setState({
      ps: value
    })
  }

  handleSubmit=()=>{
    const { bw, no,ps } = this.state
    const data = {
      bool: true,
      msg: 'Please verify if all fields are selected',
      type: 'warning'
    }
    if(bw && no && ps){
      this.props.calculate(bw, no, ps)
    } else {
      this.props.showMessage(data)
    }
  }

  handleOOB=()=>{
    this.props.handleOob()
  }

  render() {
    const balansTip= 'Net asset value = Total assets - Total liabilities'
    const nettoTip= 'Net Revenue = Gross revenue - Cost of goods sold/Cost of services sold'
    const personeelTip= 'The average number of full-time employees'
    return (
      <div className="input-section">
        <p className="intro">
       In accordance with the definition as included in the Surinamese Law of Financial Statements we pay attention to these three criteria to determine which IFRS standard your company must meet:
        </p>
        <div className="select-fields">

          <div className="select-field">
            <div className="label">
              <p>Net Asset Value</p>
              <Popover content={balansTip}>
              <span><i className="icofont-info-circle"></i></span>
              
              </Popover>
            </div>
            <Select onChange={this.selectBalanswaarde} style={{minWidth: 240}}>
              <Option value="<=3mln">Less or equal to SRD 3 mln.</Option>
              <Option value=">3mln">Between SRD 3 and 12 mln.</Option>
              <Option value=">12mln">Greater then SRD 12 mln.</Option>
            </Select>
          </div>

          <div className="select-field">
            <div className="label">
              <p>Net Revenue</p>
              <Popover content={nettoTip}>
              <span><i className="icofont-info-circle"></i></span>
              </Popover>
            </div>
            <Select onChange={this.selectNettoOmzet} style={{minWidth: 240}}>
              <Option value="<=6mln">Less or equal to SRD 6 mln.</Option>
              <Option value=">6mln">Between SRD 6 and SRD 24 mln.</Option>
              <Option value=">24mln">Greater then SRD 24 mln.</Option>
            </Select>
          </div>

          <div className="select-field">
            <div className="label">
              <p>Personnel</p>
              <Popover content={personeelTip}>
              <span><i className="icofont-info-circle"></i></span>
              </Popover>
            </div>
            <Select onChange={this.selectPersoneel} style={{minWidth: 240}}>
              <Option value="<=20">Less or equal to 20 persons</Option>
              <Option value=">20">Between 20 and 50 persons</Option>
              <Option value=">50">More than 50 persons</Option>
            </Select>
          </div>

        </div>
        <button onClick={this.handleSubmit}>ANALYZE</button>
        <p className="extra">Do you think your company is a Public Interest Entity? Click <span onClick={this.handleOOB}>here</span> to see which companies are covered. </p>
      </div>
    );
  }
}

export default connect(null, {calculate, showMessage, handleOob})(InputSection);
