export const CALCULATING = 'CALCULATING'

export const LOADER = 'LOADER'

export const MESSAGE = 'MESSAGE'

export const VIDEO_MODAL = 'VIDEO_MODAL'

export const OOB = 'OOB'

export const RESET_RESULT = 'RESET_RESULT'

export const TOGGLE_SECRET = 'TOGGLE_SECRET'

export const SHOW_ADMIN_MODAL = 'SHOW_ADMIN_MODAL'

export const GET_RESULTS = 'GET_RESULTS'

export const GET_MESSAGES = 'GET_MESSAGES'

export const GET_COUNTER_RESULTS = 'GET_COUNTER_RESULTS'