import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-container">
          <div>
            <h1>Hintzen Administration Consulting</h1>
            <p>
              We deliver quality services according to specific client needs
            </p>
          </div>
          <div>
            <h1>Contact us</h1>
            <div className="adres">
              <i className="icofont-location-pin"></i>
              <p>Ring Center Plaza Ringweg Zuid 193-195 Paramaribo, Suriname</p>
            </div>
            <div className="email">
              <i className="icofont-email"></i>
              <a
                href="mailto: 
info@hintzenadministration-consulting.com"
              >
                info@hintzenadministration-consulting.com
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
