import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateDatabaseMessages } from '../../redux/actions/miscActions'
export class ContactForm extends Component {
  constructor(props){
    super(props);
    this.state={
      name: '',
      email: '',
      phone: '',
      msg: '',
      honeypot: ''
    }
  }

  handleChange=(e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit=()=>{  
    const { name, email, phone, msg } = this.state
    const { rechtspersoon } = this.props.misc
    const data = {
      rechtspersoon, name, email, phone, msg 
    }
    this.props.updateDatabaseMessages(data)
  }

  render() {

    return (
      <div className="contact-form">
        <p className="header">Getting ready for IFRS can be challenging. We can help.
        Contact us for a free consultation.</p>
        <div>
          <p>Name</p>
          <input type="text" onChange={this.handleChange} name="name" placeholder="John Doe"/>
        </div>
        <div>
          <p>Email</p>
          <input type="text" onChange={this.handleChange} name="email" placeholder="johndoe@gmail.com"/>
        </div>
        <div>
          <p>Phone</p>
          <input type="text" onChange={this.handleChange} name="phone" placeholder="(+597) 444 555"/>
        </div>
        <div>
          <p>Message</p>
          <textarea type="text" onChange={this.handleChange} name="msg" placeholder="Type your message here"/>
        </div>
        <button onClick={this.handleSubmit}>Submit</button>
      </div>
    )
  }
}

const mapStateToProps=(state)=>({
  misc: state.misc
})

export default connect(mapStateToProps, {updateDatabaseMessages})(ContactForm)
