import { CALCULATING, GET_COUNTER_RESULTS, GET_MESSAGES, GET_RESULTS, LOADER, MESSAGE, OOB, RESET_RESULT, SHOW_ADMIN_MODAL, TOGGLE_SECRET, VIDEO_MODAL } from "../actions/actionTypes";


const initialState={
  rechtspersoon: false,
  loading: false,

  showMessage:false,
  messageData: '',
  messageType: '',

  showVideoModal: false,
  video: '',

  isOob: 'false',

  showSecret: false,

  showAdminModal: false,

  results: [],

  messages: [],
  
  counter: [],
}

export default function(state=initialState, action){
  switch(action.type){
    case CALCULATING:
      return{
        ...state,
        rechtspersoon: action.payload,
        loading: false
      }
      
      case OOB:
        return{
          ...state,
          rechtspersoon: 'oob',
          loading: false
      }

    case RESET_RESULT:
      return{
        ...state,
        rechtspersoon: false
      }

    case LOADER:
      return{
        ...state,
        loading: action.payload
      }

    case MESSAGE:
      return{
        ...state,
        showMessage: action.payload.bool,
        messageData: action.payload.msg,
        messageType: action.payload.type
      }

    case VIDEO_MODAL:
      return{
        ...state,
        showVideoModal: action.payload.bool,
        video: action.payload.video
      }

    case TOGGLE_SECRET:
      return{
        ...state,
        showSecret: action.payload
      }

    case SHOW_ADMIN_MODAL:
      return{
        ...state,
        showAdminModal: action.payload
      }

    case GET_RESULTS:
      return{
        ...state,
        results: action.payload
      }

    case GET_MESSAGES:
      return{
        ...state,
        messages: action.payload
      }

    case GET_COUNTER_RESULTS:
      return{
        ...state,
        counter: action.payload
      }
      
      default:
      return state
  }
}
