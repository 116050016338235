import React, { Component } from "react";
import { connect } from "react-redux";
import { updateSme } from "../redux/actions/miscActions";

export class MiddelGroot extends Component {
  handleIncrement = () => {
    this.props.updateSme();
  };

  render() {
    return (
      <div className="results-data">
        <p className="main">Size of the legal entity:</p>
        <h1>Medium-sized legal entity</h1>
        <p className="sub">
          Reporting standard to be used: <span>IFRS for SME</span>
        </p>
        <div className="result-par">
          <ol>
            <li>
              Two book years to be presented on the Statement of Financial
              Position
            </li>
            <p>
              <strong>Example:</strong>
            </p>
            <p>
              Implementing IFRS for SMEs in a company with a book year ending
              December 31, 2020 would require presentation of the following
              year-end balances on its Statement of Financial Position:
            </p>
            <table>
              <thead>
                <tr>
                  <td>December 31, 2020</td>
                  <td>December 31, 2019</td>
                </tr>
              </thead>
            </table>
            <li>
              Full set of financial statements which consist of the following:{" "}
              <ul>
                <li>Statement of Profit or Loss</li>
                <li>Statement of Comprehensive Income</li>
                <li>Statement of Financial Position</li>
                <li>Statement of Changes in Equity</li>
                <li>Statement of Cash Flows</li>
                <li>Notes to Financial Statements </li>
              </ul>
            </li>
            <li>
              Disclosures on Restatements and First-Time Adoption of IFRS for
              SMES presenting all IFRS for SMEs conversion adjustments and the
              impact it has had on the previous accounting procedures.
            </li>
          </ol>
        </div>
        <a
          href="https://hintzenadministration-consulting.com/wp-content/uploads/2021/12/Guidelines-for-IFRS-for-SME.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button onClick={this.handleIncrement} className="download">
            Download
          </button>
        </a>
      </div>
    );
  }
}

export default connect(null, { updateSme })(MiddelGroot);
