import React, { Component } from 'react'
import { Table } from 'antd'
import  dayjs from 'dayjs'

export class Messages extends Component {
  render() {
    const { msg } = this.props
    const data = msg.sort((a, b) => b.date.toDate() - a.date.toDate())
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '15%',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        width: '13%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '14%',
      },
      {
        title: 'Result',
        dataIndex: 'rechtspersoon',
        key: 'rechtspersoon',
        width: '5%',
      },
      {
        title: 'Message',
        dataIndex: 'msg',
        key: 'msg',
        width: '37%',
        textWrap: 'word-break',
        render: msg =>  <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{msg}</p>
      },
      {
        title: 'Date',
        dataIndex: 'date',
        width: '16%',
        key: 'date',
        render: date => dayjs(date.seconds * 1000 ).format('DD MMM YY @ hh:mm A')
      }
    ]
    return (
      <div>
        <p>Messages</p>
        <Table columns={columns} dataSource={data} />
      </div>
    )
  }
}

export default Messages
