import React, { Component } from 'react'
import { connect } from "react-redux";
import { showAdminModal, getresults, getmsg, getDocCollection } from '../../redux/actions/miscActions'
import { Modal, Tabs } from "antd";
import Results from './Results';
import Messages from './Messages';
const { TabPane } = Tabs;

export class Admin extends Component {
  handleClose=()=>{
    this.props.showAdminModal(false)
  }

  componentDidMount=()=>{
    this.props.getmsg()
    this.props.getresults()
    this.props.getDocCollection()
  }

  render() {
    const { showAdminModal, results, messages, counter } = this.props.misc
  
    return (
      <div className="admin-page">
        <Modal
          visible={showAdminModal}              
          onCancel={this.handleClose}
          footer={null}          
          width='98%'
        >
           <Tabs defaultActiveKey="1">
    <TabPane tab="Results" key="1">
      <Results results={results} />
    </TabPane>
    <TabPane tab="Messages" key="2">
      <Messages msg={messages}/>
    </TabPane>
    <TabPane tab="Download counter" key="3">
      {counter.map(x =>  {return (
        <div className="counter-results" key={x.name}>
          <p>{x.name}: {x.downloaded}</p>
        </div>
      )})}
    </TabPane>
  </Tabs>

        </Modal>
      </div>
    )
  }
}

const mapStateToProps=(state)=>({
  misc: state.misc
})

export default connect(mapStateToProps, {showAdminModal, getresults, getmsg, getDocCollection})(Admin)
