import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showAdminModal, toggleSecret } from '../../redux/actions/miscActions'

export class Navbar extends Component {
  constructor(props){
    super(props)
    this.state={
      secret: ''
    }
  }

  handleInput=(e)=>{
    this.setState({
      secret: e.target.value
    })
  }

  componentDidUpdate=()=>{
    if(this.state.secret === 'secret'){
      this.setState({
        secret: ''
      })
      this.props.toggleSecret(false)
      this.props.showAdminModal(true)
    }
  }



  render() {
    const { showSecret } = this.props.misc
    return (
      <div className="navbar">
        <div className="navbar-container">
          <div className="brand">
            <img src={require('../../assets/images/hinten-administration-consulting.png')} alt="Hintzen Administration Consulting Logo"/>
          {showSecret ? <input type="text" className="secret" onChange={this.handleInput}/> : null}
          </div>
          <div className="nav">
            <a href="https://hintzenadministration-consulting.com">Home</a>
          </div>
        </div>        
      </div>
    )
  }
}

const mapStateToProps=(state)=>({
  misc: state.misc
})

export default connect(mapStateToProps, { showAdminModal, toggleSecret })(Navbar)
