import React from 'react';

import "antd/dist/antd.css";
import './App.css';
import { connect } from 'react-redux'
import { toggleSecret } from './redux/actions/miscActions'
import Hero from './components/Hero';
import Footer from './components/layout/Footer';
import Navbar from './components/layout/Navbar';
import InputSection from './components/InputSection'
import Loader from './components/utils/Loader';
import MessageModal from './components/utils/MessageModal';
import Results from './components/Results';
import VideoModal from './components/utils/VideoModal';
import Admin from './components/admin/Admin';

function App(props) {
  window.addEventListener('keypress', (event)=>{
    const {charCode, shiftKey} = event
    if(charCode === 123 && shiftKey === true){
      props.toggleSecret(true)
    }    
  })
  const { loading, rechtspersoon } = props.misc
  return (
    <div className="App">
     
      <Admin />
      <MessageModal />
      <VideoModal />
      <Navbar />
<div className="main-container">
    <Hero />
    <InputSection />
    { loading ? <Loader /> : null}
    {loading === false && rechtspersoon !== false ? <Results /> : null}
</div>
    <Footer />
    </div>
  );
}

const mapStateToProps=(state)=>({
  misc: state.misc
})

export default connect(mapStateToProps, { toggleSecret })(App);
