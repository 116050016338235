import React, { Component } from "react";
import { connect } from "react-redux";
import { showMessage } from "../../redux/actions/miscActions";
import { Modal } from "antd";

export class MessageModal extends Component {
  handleClose = () => {
    const data = {
      bool: false,
      msg: "",
      type: "",
    };
    this.props.showMessage(data);
  };
  render() {
    const { showMessage, messageData, messageType } = this.props.misc;
    return (
      <div className="message-modal">
        <Modal
          visible={showMessage}
          closable={false}
          centered={true}
          footer={null}
          mask={false}
          width='360px'
        >
          <div className="modal-container">
            <div className="message-type">
            {messageType === "warning" ? <i className="icofont-warning"></i>: null}
                {messageType === "success" ? <i className="icofont-check"></i> : null}
              
            <div className="message-body">
              <p className="modal-type-title">
                {messageType === "warning" ? "Alert" : null}
                {messageType === "success" ? "Success" : null}
              </p>
              <p>{messageData}</p>
            <button onClick={this.handleClose}>CLOSE</button>
            </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  misc: state.misc,
});

export default connect(mapStateToProps, { showMessage })(MessageModal);
