import React, { Component } from 'react'

export class Klein extends Component {
  render() {
    return (
      <div className="results-data">
        <p className="main">Size of the legal entity:</p>
        <h1>Small-sized legal entity</h1>
        <p className="sub">
          Reporting standard to be used: <span>GAAP</span>
        </p>
        <div className="result-par small">
        <p>GAAP is short for Generally Accepted Accounting Principles. GAAP is a cluster of accounting standards and common industry usage that have been developed over many years. It is used by organizations to:</p>
          <ol>
            <li>
            Properly organize their financial information into accounting records
            </li>            
            <li>
            Summarize the accounting records into financial statements
            </li>
            <li>Disclose certain supporting information</li>
          </ol>
          <p>One of the reasons for using GAAP is so that anyone reading the financial statements of multiple companies has a reasonable basis for comparison, since all companies using GAAP have created their financial statements using the same set of rules.</p>
        </div>
        {/* <button className="download">Download</button> */}
      </div>
    )
  }
}

export default Klein
